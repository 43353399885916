<template>
  <div class="py-4">
    <div v-if="isAdmin" class="h-20 w-full flex justify-center items-center">
      <b-field :type="inputType" :message="inputMessage" class="w-full">
        <input
          v-model="biewUser"
          class="
            mt-1
            px-3
            py-2
            focus:ring-indigo-500
            focus:border-indigo-500
            block
            w-full
            shadow-sm
            sm:text-sm
            border border-gray-300
            rounded-md
          "
          placeholder="Correo de usuario"
          @keyup.enter="findUser"
        />
      </b-field>
    </div>
    <div v-if="isAdmin" class="h-20 w-full flex justify-center items-center">
      <b-field :type="inputType" class="w-full">
        <b-select
          v-model="website"
          placeholder="Websites"
          expanded
          icon="filter"
        >
          <option
            v-for="field in userAdmin.websites"
            :key="`filter-field-${field.websiteId}`"
            :value="field"
          >
            {{ field.domain }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="h-20 w-full flex justify-center items-center">
      <b-field :type="inputType" :message="inputMessage" class="w-full">
        <b-input
          v-model="sheetUrl"
          :placeholder="$t('builder_insert_sheet.insert_spreadsheet')"
          custom-class="w-full placeholder-gray-500"
          class="w-full text-center"
          size="is-large"
          icon="table"
          expanded
          autofocus
          :loading="loading"
          :disabled="isAdmin"
        ></b-input>
      </b-field>
    </div>
    <!--  -->
    <div>
      <div class="w-full text-left mt-3 font-medium">
        <a
          :class="classes.showHowToSetPublicSpreadsheet"
          @click="openModal.tutorial = true"
        >
          How to solve this problem?
        </a>
      </div>
    </div>
    <!--  -->
    <p class="mt-4 text-gray-600">
      {{ $t("builder_insert_sheet.id_value") }} "/d/"
      {{ $t("builder_insert_sheet.and_the") }} "/edit"
      {{ $t("builder_insert_sheet.url_spreadsheet") }}
    </p>
    <div class="mt-4 p-4 bg-gray-100 break-words text-gray-600 rounded-lg">
      https://docs.google.com/spreadsheets/d/
      <span class="text-green-800 font-bold">spreadsheetId</span>/edit#gid=0
    </div>
    <div class="w-full text-center mt-3 font-medium text-green-600">
      <a
        class="underline hover:text-green-900"
        href="https://drive.google.com/drive/search?q=type:spreadsheet"
        target="_blank"
      >
        {{ $t("builder_insert_sheet.open_spreadsheets") }}
      </a>
    </div>
    <div class="w-full text-center mt-3 font-medium text-gray-600">
      <a
        class="underline"
        href="https://docs.google.com/spreadsheets/d/1kEcZrvpe2hsTK4Ic-mITi9SGn2cnwe0KjYyvu9zDyaA/edit#gid=0"
        target="_blank"
      >
        {{ $t("builder_insert_sheet.open_example_spreadsheet") }}
      </a>
    </div>
    <div class="w-full text-center mt-3 font-medium text-gray-600">
      <a
        class="underline"
        href="https://www.youtube.com/watch?v=jRV4dgVF-Wc"
        target="_blank"
      >
        {{ $t("builder_insert_sheet.watch_tutorial") }}
      </a>
    </div>
    <tutorial-modal
      v-show="openModal.tutorial"
      @close="openModal.tutorial = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.tutorial-link {
  color: #0a69ff;
  border: 0;
}
</style>

<script>
import _debounce from "lodash-es/debounce";
import TutorialModal from "@/components/TutorialModal/TutorialModal.vue";
import { mapState } from "vuex";

export default {
  name: "BuilderInsertSheet",
  components: {
    TutorialModal
  },
  data() {
    return {
      loading: false,
      inputMessage: "",
      inputType: "",
      biewUser: "",
      openModal: {
        tutorial: false
      }
    };
  },

  computed: {
    ...mapState("builder", ["hasErrorSpreadsheet"]),
    classes() {
      return {
        showHowToSetPublicSpreadsheet: {
          hidden: !this.hasErrorSpreadsheet,
          underline: true,
          "tutorial-link": true
        }
      };
    },
    editMode() {
      return this.$store.state.builder.editMode;
    },
    sheetUrl: {
      get() {
        return this.$store.state.builder.sheetId
          ? `https://docs.google.com/spreadsheets/d/${this.$store.state.builder.sheetId}`
          : "";
      },
      set(val) {
        const spreadsheetId =
          val.length > 44
            ? new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(val)[1] || val
            : val;
        this.$store.commit("builder/SET_SHEET_ID", spreadsheetId);
      }
    },
    sheetId() {
      return this.$store.state.builder.sheetId;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    userAdmin() {
      return (
        this.$store.state.builder.userAdmin || {
          websites: []
        }
      );
    },
    website: {
      get() {
        return this.userAdmin.websites
          ? this.userAdmin.websites.find(
              ({ websiteId }) =>
                this.$store.state.builder.websiteId === websiteId
            )
          : "";
      },
      set(val) {
        this.$store.dispatch("builder/setEditWebsite", val);
        if (val?.theme?.font?.cdn && val?.theme?.font?.name) {
          document.getElementById(
            "preview"
          ).style.fontFamily = `'${val?.theme?.font?.name}'`;
        } else {
          document.getElementById("preview").removeAttribute("style");
        }
      }
    }
  },

  watch: {
    sheetId() {
      this.getSheet();
    }
  },

  created() {
    this.getSheetDelayed = _debounce(this.getSheet, 600);
  },

  mounted() {
    this.biewUser = this.$store.state.builder.userAdmin?.profile?.email || "";
    if (this.editMode && this.sheetId) {
      this.getSheet();
    }
  },
  methods: {
    async getSheet() {
      try {
        this.loading = true;
        await this.$store.dispatch("builder/getSheet", {
          sheetId: this.sheetId
        });
        this.inputType = "is-success";
        this.inputMessage = "";
      } catch (error) {
        this.inputType = "is-danger";
        if (error?.response?.data?.message) {
          this.inputMessage = error.response.data.message;
        } else if (error?.response?.status === 404) {
          this.inputMessage = this.$t(
            "builder_insert_sheet.spreadsheet_not_found"
          );
        } else {
          this.inputMessage = this.$t("builder_insert_sheet.spreadsheet_error");
        }
      } finally {
        this.loading = false;
      }
    },
    async findUser() {
      this.$store.dispatch("builder/findUserWebsites", this.biewUser);
    }
  }
};
</script>
