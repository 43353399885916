<template>
  <div>
    <div class="field">
      <b-switch v-model="showHero">
        {{ $t("page_header.show") }}
      </b-switch>
    </div>

    <b-field :label="$t('page_header.title')">
      <div class="flex w-full">
        <b-input
          v-model="title"
          placeholder=""
          class="w-full"
          expanded
        ></b-input>
        <color-picker v-model="titleColor" />
      </div>
    </b-field>

    <b-field :label="$t('page_header.subtitle')">
      <div class="flex">
        <b-input
          v-model="subheading"
          placeholder=""
          class="w-full"
          expanded
        ></b-input>
        <color-picker v-model="subheadingColor" />
      </div>
    </b-field>

    <b-field
      :label="$t('page_header.image')"
      :type="imageFieldType"
      :message="$t('page_header.image_limit')"
    >
      <b-upload
        v-model="imageFile"
        expanded
        accept=".jpg,.jpeg,.png,.webp"
        class="text-red"
        :loading="uploadingImage"
        :disabled="uploadingImage"
      >
        <a class="button is-fullwidth">
          <b-icon name="upload" class="mr-3"></b-icon>
          <span v-if="uploadingImage">{{ $t("page_header.uploading") }}</span>
          <span v-else>{{
            $t(
              imageUploaded
                ? "page_header.replace_image"
                : "page_header.upload_image"
            )
          }}</span>
        </a>
      </b-upload>
    </b-field>

    <b-field v-if="imageUploaded" class="gap-3">
      <div
        class="relative mt-2 p-2 border border-gray-400 rounded flex justify-center items-center w-full h-40"
      >
        <!-- Imagen -->
        <img :src="imageUploaded" class="w-full h-full object-cover" />

        <!-- Botón eliminar encima de la imagen en la esquina superior derecha -->
        <div class="absolute top-0 right-0 m-4">
          <b-tooltip :label="$t('page_header.remove_image')">
            <b-button
              icon-right="trash"
              class="h-10"
              style="background-color: rgba(255, 255, 255, 0.7);"
              @click="deleteImage"
            />
          </b-tooltip>
        </div>
      </div>

      <div class="mt-2">
        <!-- Aquí puedes agregar otros elementos si es necesario -->
      </div>
    </b-field>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import ColorPicker from "@/components/ColorPicker";
import * as mutation from "@/store/modules/builder/mutation-types";
import { fileSize } from "@/utils/file";

export default {
  name: "PageHeader",

  components: {
    BIcon,
    ColorPicker
  },

  data() {
    return {
      imageFile: null,
      uploadingImage: false,
      localImage: null
    };
  },

  computed: {
    showHero: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.show;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_LIST_ITEM_HERO_SHOW}`, val);
      }
    },
    title: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.title?.value;
      },
      set(value) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_TITLE_VALUE}`,
          value
        );
      }
    },
    titleColor: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.title?.color;
      },
      set(color) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_TITLE_COLOR}`,
          color
        );
      }
    },
    subheading: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.subheading
          ?.value;
      },
      set(value) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_SUBHEADING_VALUE}`,
          value
        );
      }
    },
    subheadingColor: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.subheading
          ?.color;
      },
      set(value) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_SUBHEADING_COLOR}`,
          value
        );
      }
    },
    invalidImage() {
      if (!this.imageFile) return false;
      return fileSize(this.imageFile) > 2;
    },
    imageFieldType() {
      return this.invalidImage ? "is-danger" : "";
    },
    imageUploaded() {
      if (this.uploadingImage && this.localImage) {
        return this.localImage;
      }
      return (
        this.$store.state.builder.listItemConfig?.hero?.image?.value || null
      );
    }
  },

  watch: {
    imageFile(newFile) {
      if (!newFile || this.invalidImage) return;
      this.uploadingImage = true;
      this.$store
        .dispatch("builder/uploadHeaderImage", newFile)
        .then(() => {
          this.localImage = this.$store.state.builder.listItemConfig?.hero?.image?.value;
        })
        .finally(() => {
          this.uploadingImage = false;
        });
    }
  },

  methods: {
    deleteImage() {
      this.localImage = null;
      this.imageFile = null;
      this.$store.commit(`builder/${mutation.SET_LIST_ITEM_HERO_IMAGE}`, "");
    }
  }
};
</script>
